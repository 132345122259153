import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';

const Login = lazy(() => import('../views/Login'));
const Home = lazy(() => import('../views/Home'));
const AprendeMas = lazy(() => import('../views/AprendeMas'));
const FlashTalks = lazy(() => import('../views/FlashTalks'));
const ContenidoPlus = lazy(() => import('../views/ContenidoPlus'));
const LiveEvents = lazy(() => import('../views/LiveEvents'));
const Nosotros = lazy(() => import('../views/Nosotros'));
const SpiraHub = lazy(() => import('../views/SpiraHub'));
const Proximamente = lazy(() => import('../views/Proximamente'));
const Agenda = lazy(() => import('../views/Agenda'));
const Pruebas = lazy(() => import('../views/Pruebas'));
const Falso = lazy(() => import('../views/AprendeMasFALSO'));

// panel de administracion
const LoginAdministrador = lazy(() => import('../views/Adminstracion/Login'));
const HomeAdministrador = lazy(() => import('../views/Adminstracion/Home'));
const FlashTalksAdministrador = lazy(() => import('../views/Adminstracion/FlashTalks'));
const FlashTalksCrearAdministrador = lazy(() => import('../views/Adminstracion/FlashTalks/Crear'));
const FlashTalksActualizadAdministrador = lazy(() => import('../views/Adminstracion/FlashTalks/Actualizar'));
const FlashTalksActivarAdministrador = lazy(() => import('../views/Adminstracion/FlashTalks/Activar'));
const RecursosAdministrador = lazy(() => import('../views/Adminstracion/Recursos'));
const UsuariosAdministrador = lazy(() => import('../views/Adminstracion/Usuarios'));
const UsuariosCrearAdministrador = lazy(() => import('../views/Adminstracion/Usuarios/Crear'));
const UsuariosActualizarAdministrador = lazy(() => import('../views/Adminstracion/Usuarios/Actualizar'));
const RecursosCrearAdministrador = lazy(() => import('../views/Adminstracion/Recursos/Crear'));
const AgendaAdministrador = lazy(() => import('../views/Adminstracion/Agenda'));
const AgendaCrearAdministrador = lazy(() => import('../views/Adminstracion/Agenda/Crear'));
const AgendaActualizarAdministrador = lazy(() => import('../views/Adminstracion/Agenda/Actualizar'));


export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/inicio" element={<Home />} />
                    <Route exact path="/aprende-mas" element={<AprendeMas />} />
                    <Route exact path="/contenido-plus" element={<ContenidoPlus />} />
                    <Route exact path="/flash-talks" element={<FlashTalks />} />
                    <Route exact path="/live-events" element={<LiveEvents />} />
                    <Route exact path="/nosotros" element={<Nosotros />} />
                    <Route exact path="/agenda" element={<Agenda />} />
                    <Route exact path="/contactanos" element={<Proximamente />} />
                    <Route exact path="/spira-hub" element={<SpiraHub />} />
                    <Route exact path="/pruebas/:userId/:name" element={<Pruebas />} />
                    <Route exact path="/pruebas/falso" element={<Falso />} />
                    <Route exact path="/admin/login" element={<LoginAdministrador />} />
                    <Route exact path="/admin/dashboard" element={<HomeAdministrador />} />
                    <Route exact path="/admin/flash-talks" element={<FlashTalksAdministrador />} />
                    <Route exact path="/admin/flash-talks/crear" element={<FlashTalksCrearAdministrador />} />
                    <Route exact path="/admin/flash-talks/activar" element={<FlashTalksActivarAdministrador />} />
                    <Route exact path="/admin/flash-talks/:id/actualizar" element={<FlashTalksActualizadAdministrador />} />
                    <Route exact path="/admin/usuarios" element={<UsuariosAdministrador />} />
                    <Route exact path="/admin/usuarios/crear" element={<UsuariosCrearAdministrador />} />
                    <Route exact path="/admin/usuarios/:id/actualizar" element={<UsuariosActualizarAdministrador />} />
                    <Route exact path="/admin/recursos" element={<RecursosAdministrador />} />
                    <Route exact path="/admin/recursos/crear" element={<RecursosCrearAdministrador />} />
                    <Route exact path="/admin/agenda" element={<AgendaAdministrador />} />
                    <Route exact path="/admin/agenda/crear" element={<AgendaCrearAdministrador />} />
                    <Route exact path="/admin/agenda/:id/actualizar" element={<AgendaActualizarAdministrador />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}